import React from 'react'
import SecondaryHeading from '../../General/Headings/SecondaryHeading'
import Paragraph from '../../General/Paragraph'
import SplitContentContainer from '../../General/SplitContent/SplitContentContainer'
import ContentVideo from '../../General/ContentVideo'

const YouTubeTout = ({ heading, description, youTubeHtml }) => (
  <SplitContentContainer
    vertCenter={true}
    content={() => {
      return (
        <>
          <h2>{ heading }</h2>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </>
      )
    }}
    contentAside={() => {
      return (
        <ContentVideo embedHtml={youTubeHtml} />
      )
    }}
  />
)

export default YouTubeTout
