import React from 'react'
import Button from '../Buttons/Button'
import SmartLink from '../SmartLink'

const UseCaseCard = ({ title, description, image, linkText, link }) => (
  <article className="flex flex-col items-start h-full p-4 font-sans shadow-blur border border-light-grey-2 simple-fade-in relative hover:border-grey hover:shadow-lg">
    {image && image.src && (
      <div
        className="bg-contain bg-left bg-no-repeat mb-2"
        style={{
          backgroundImage: `url('${image && image.src}')`,
          width: '60%',
          height: '80px',
          backgroundPosition: 'center left',
        }}
      ></div>
    )}
    <h4 className="mb-2 text-2xl">{title}</h4>
    <p className="mb-6 leading-normal">{description}</p>
    {link && (
      <SmartLink to={link} className="mt-auto block no-underline">
        <Button className="mt-auto">{linkText}</Button>
      </SmartLink>
    )}
  </article>
)

export default UseCaseCard
