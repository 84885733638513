import React from 'react'
import classNames from 'classnames'

const baseHeadingClasses = `
  font-sans
  font-bold
  text-xl
  lg:text-2xl
`

const TertiaryHeading = ({ children, whiteText }) => (
  <h3
    className={classNames(baseHeadingClasses, {
      'text-white': whiteText,
      'text-charcoal': !whiteText
    })}>{ children }</h3>
)

export default TertiaryHeading
