import React from 'react'
import { graphql } from 'gatsby'

// components
import PageWrap from '../components/Layouts/Global/PageWrap'
import SmartLink from '../components/General/SmartLink'
import { withPreview } from 'gatsby-source-prismic'

// dynamic components
import WysiwygContent from '../components/General/WysiwygContent'
import Mantel from '../components/General/Mantel'
import YouTubeTout from '../components/General/YouTubeTout'
import CallToAction from '../components/General/CallToAction'
import ExternalNewsFeed from '../components/General/ExternalNewsFeed'
import Button from '../components/General/Buttons/Button'
import NotificationBar from '../components/General/NotificationBar'
import {
  UseCaseCardGrid,
  transformData as transformUseCaseCardData,
} from '../components/General/UseCaseCard'

// utils
// import mergePreviewData from '../utils/mergePreviewData'
import SectionIntro from '../components/General/SectionIntro'
import TwoUp from '../components/Layouts/TwoUp'

const SectionWrap = ({ children }) => (
  <div className="my-6 md:my-8">{children}</div>
)

const slices = {
  text_two_up({ items }) {
    return (
      <TwoUp>
        {items.map((item) => {
          return (
            <div>
              <WysiwygContent>
                <h2>{item.text_two_up_heading.text}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.text_two_up_copy.html,
                  }}
                ></div>
              </WysiwygContent>
              {item.text_two_up_button_text.text &&
                item.text_two_up_button_link.url && (
                  <SmartLink
                    className="no-underline"
                    to={item.text_two_up_button_link.url}
                  >
                    <Button>{item.text_two_up_button_text.text}</Button>
                  </SmartLink>
                )}
            </div>
          )
        })}
      </TwoUp>
    )
  },
  youtube_tout({ primary }) {
    return (
      <YouTubeTout
        heading={primary.youtube_tout_heading.text}
        description={primary.youtube_tout_copy.html}
        youTubeHtml={primary.youtube_tout_video.html}
      />
    )
  },
  featured_banner({ primary }) {
    return (
      <CallToAction
        isSecondary
        heading={primary.featured_banner_copy.text}
        button={{
          route: primary.featured_banner_button_link.url,
          text: primary.featured_banner_button_text.text,
        }}
        bgImageSrc={primary.featured_banner_background_image.url}
      />
    )
  },
  news_section({ primary, items }) {
    return (
      <>
        <SectionIntro title={primary.news_section_heading.text} />
        <ExternalNewsFeed
          items={items.map((item) => ({
            date: item.news_item_publication_date.text,
            publicationName: item.news_item_publication_name.text,
            heading: item.news_item_heading.text,
            url: item.news_item_link.url,
            buttonLabel: item.news_item_button_text.text,
          }))}
        />
      </>
    )
  },
  announcement({ primary }) {
    return (
      <NotificationBar
        dismissible={false}
        message={primary.announcement_copy.text}
        buttons={[
          {
            url: primary.announcement_button_link.url,
            text: primary.announcement_button_text.text,
          },
        ]}
      />
    )
  },
  use_cases_section({ primary, items }) {
    if (!items) return null
    return (
      <>
        <SectionIntro title={primary.use_cases_section_heading.text} />
        <UseCaseCardGrid
          items={items.map(
            (item) =>
              item.use_cases_use_case.document &&
              transformUseCaseCardData(item.use_cases_use_case.document)
          )}
        />
      </>
    )
  },
}

const transformData = function (page) {
  const title = page.data.use_case_title.text
  return {
    seo: {
      title,
      description: page.data.meta_description,
    },
    title,
    mantelData: {
      show: true,
      thiccHeading: true,
      title,
      centeredDesc: true,
      description: page.data.categories
        .filter(({ category }) => category.document)
        .map(({ category }) => category.document.data.name.text)
        .join(', '),
    },
  }
}

const UseCase = ({ data }) => {
  const { prismicUseCase } = data
  const { seo, mantelData } = transformData(prismicUseCase)

  return (
    <PageWrap bg={3} seo={seo}>
      {mantelData.show && <Mantel {...mantelData}></Mantel>}
      <div className={'max-w-3xl mx-auto'}>
        {prismicUseCase.data.body.map((slice) => {
          const Component = slices[slice.slice_type]

          if (!Component) return null

          return (
            <SectionWrap>
              <Component {...slice} />
            </SectionWrap>
          )
        })}
      </div>
    </PageWrap>
  )
}

export default withPreview(UseCase)

export const pageQuery = graphql`
  query($uid: String!, $locale: String!) {
    prismicUseCase(lang: { eq: $locale }, uid: { eq: $uid }) {
      data {
        use_case_title {
          text
        }
        categories {
          category {
            document {
              ... on PrismicUseCaseCategory {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
        }
        meta_description
        body {
          ... on PrismicUseCaseBodyTextTwoUp {
            slice_type
            items {
              text_two_up_heading {
                text
              }
              text_two_up_copy {
                html
              }
              text_two_up_button_text {
                text
              }
              text_two_up_button_link {
                url
              }
            }
          }
          ... on PrismicUseCaseBodyFeaturedBanner {
            slice_type
            primary {
              featured_banner_copy {
                text
              }
              featured_banner_button_text {
                text
              }
              featured_banner_button_link {
                url
              }
              featured_banner_background_image {
                url
              }
            }
          }
          ... on PrismicUseCaseBodyYoutubeTout {
            slice_type
            primary {
              youtube_tout_heading {
                text
              }
              youtube_tout_copy {
                html
              }
              youtube_tout_video {
                html
              }
            }
          }
          ... on PrismicUseCaseBodyNewsSection {
            slice_type
            primary {
              news_section_heading {
                text
              }
            }
            items {
              news_item_heading {
                text
              }
              news_item_publication_date {
                text
              }
              news_item_publication_name {
                text
              }
              news_item_button_text {
                text
              }
              news_item_link {
                url
              }
            }
          }
          ... on PrismicUseCaseBodyAnnouncement {
            slice_type
            primary {
              announcement_copy {
                text
              }
              announcement_button_text {
                text
              }
              announcement_button_link {
                url
              }
            }
          }
          ... on PrismicUseCaseBodyUseCasesSection {
            slice_type
            primary {
              use_cases_section_heading {
                text
              }
            }
            items {
              use_cases_use_case {
                document {
                  ... on PrismicUseCase {
                    ...useCaseCardFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
