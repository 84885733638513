import { graphql } from 'gatsby'
import UseCaseCard from './UseCaseCard'
import UseCaseCardGrid from './UseCaseCardGrid'

export default UseCaseCard

export const transformData = ({ id, url, data }) => ({
  id,
  image: {
    src: data.use_case_logo.url,
    alt: data.use_case_logo.alt,
  },
  title: data.use_case_title.text,
  description: data.use_case_excerpt.text,
  link: url,
  linkText: data.use_case_card_button_text.text,
  categories: data.categories
    .filter(({ category }) => category.document)
    .map((cat) => ({
      id: cat.category.document.prismicId,
      name: cat.category.document.data.name.text,
    })),
})

export { UseCaseCardGrid }

export const fragment = graphql`
  fragment useCaseCardFields on PrismicUseCase {
    id
    url
    prismicId
    data {
      use_case_title {
        text
      }
      use_case_excerpt {
        text
      }
      use_case_card_button_text {
        text
      }
      use_case_logo {
        url
        alt
      }
      categories {
        category {
          document {
            ... on PrismicUseCaseCategory {
              prismicId
              data {
                name {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
