import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import WysiwygContent from '../WysiwygContent'

const Container = styled.div`
  ${tw`
    w-full
    mx-auto
    lg:flex
    lg:max-w-none
  `}
  ${(props) => (props.topAlign ? tw`items-start` : '')}

  ${(props) => (props.vertCenter ? tw`items-center` : '')}

  ${(props) =>
    props.flip
      ? tw`
    lg:flex-row-reverse
  `
      : ''}
`
const Content = styled.div`
  ${tw`lg:w-1/2`}
  ${(props) => (props.flip ? tw`lg:pl-4` : tw`lg:pr-4`)}
`

const ContentSide = styled.div`
  ${tw`
    mt-2 lg:mt-0
    font-sans
    lg:flex
    lg:flex-col
    lg:justify-center
  `}

  ${(props) => (props.topAlign ? tw`lg:pb-3 lg:pt-0` : 'lg:py-3')}

  ${(props) => (props.flip ? tw`lg:pr-4` : tw`lg:pl-4`)}

  ${(props) =>
    props.hasSideContent
      ? tw`lg:w-1/2`
      : tw`
      mx-auto
      max-w-lg
      text-center
      lg:items-center
      lg:p-0
    `}
`

const SplitContentContainer = ({
  content,
  contentAside,
  topAlign = false,
  vertCenter = false,
  flip = false,
}) => {
  return (
    <Container flip={flip} topAlign={topAlign} vertCenter={vertCenter}>
      {content && (
        <Content flip={flip}>
          <WysiwygContent>{content()}</WysiwygContent>
        </Content>
      )}
      <ContentSide hasSideContent={content} flip={flip} topAlign={topAlign}>
        {contentAside && <WysiwygContent>{contentAside()}</WysiwygContent>}
      </ContentSide>
    </Container>
  )
}

export default SplitContentContainer
