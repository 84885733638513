import React from 'react'
import ThreeUp from '../../Layouts/ThreeUp'
import TertiaryHeading from '../../General/Headings/TertiaryHeading'
import TextButton from '../../General/Buttons/TextButton'

const ExternalNewsFeed = ({ items }) => (
  <ThreeUp colGap="35px" rowGap="55px">
    { items.map(item => (
      <div>
        <div className="flex items-center leading-none mb-2 font-sans font-bold text-sm">
          <div className="font-sans font-bold text-sm mt-1px">{ item.date }</div>
          <div className="mx-1 text-xs">|</div>
          <div className="uppercase mt-1px">{ item.publicationName }</div>
        </div>
        <div className="mb-2">
          <TertiaryHeading>{ item.heading }</TertiaryHeading>
        </div>
        <a
          className="no-underline"
          target="_blank"
          rel="noopener"
          href={item.url}>
          <TextButton magentaText={true}>{ item.buttonLabel }</TextButton>
        </a>
      </div>
    )) }
  </ThreeUp>
)

export default ExternalNewsFeed
