import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: ${ props => props.rowGap };
  grid-column-gap: ${ props => props.colGap };


  ${ screen('md') } {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ThreeUp = ({ children, noGaps, gap = '15px', colGap, rowGap }) => (
  <GridWrap
    colGap={colGap || gap}
    rowGap={rowGap || gap}
    style={ noGaps ? { gridGap: '0px' } : {} }
  >
    { children.map((child, i) => (
      <div key={ `three-up-item-${ i }`}>{ child }</div>
    )) }
  </GridWrap>
)

export default ThreeUp
