import React from 'react'
import classNames from 'classnames'

const baseHeadingClasses = `
  font-sans
  font-extrabold
  lg:text-35px
  text-3xl
`

const PrimaryHeading = ({ children, whiteText, level = 1, classesList }) => {
  const Heading = `h${level}`

  return (
    <Heading
      className={classNames(baseHeadingClasses, {
        'text-white': whiteText,
        'text-charcoal': !whiteText,
      }, classesList)}
    >
      {children}
    </Heading>
  )
}

export default PrimaryHeading
