import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import SmartLink from '../../General/SmartLink'
import PrimaryHeading from '../../General/Headings/PrimaryHeading'
import Paragraph from '../../General/Paragraph'
import ActionButton from '../../General/Buttons/ActionButton'
import GradientButton from '../../General/Buttons/GradientButton'
import Button from '../../General/Buttons/Button'
import bgImage from '../../../images/cta-bg.png'
import bgImage2 from '../../../images/cta-bg-2.png'

const CallToActionWrap = styled.div`
  background-image: url(${ props => props.ctaBgImage });
  ${ tw`bg-cover bg-center` }
  ${ props => props.isSecondary ? tw`py-6 lg:py-8` : tw`py-4 lg:py-6` }
`

const ContentWrap = styled.div`
  ${ tw`mx-auto px-2 lg:px-0` }
  ${ props => props.isSecondary ? tw`max-w-xl` : tw`max-w-lg` }
`

const HeadingWrap = styled.div`
  ${ tw`text-center mb-2` }
`

const HighlightBar = styled.div`
  width: 50px;
  ${ tw`h-px mx-auto mb-3` }
`

const buttonWrap = `
  mt-4
  flex
  justify-center
  no-underline
`

const CallToAction = ({ heading, text, button, isSecondary = false, bgImageSrc }) => (
  <CallToActionWrap
    isSecondary={ isSecondary }
    ctaBgImage={ bgImageSrc || (isSecondary ? bgImage2 : bgImage)}
  >
    { !isSecondary && <HighlightBar /> }
    <ContentWrap isSecondary={ isSecondary }>
      <HeadingWrap isSecondary={ isSecondary }>
        <PrimaryHeading whiteText={isSecondary}>{ heading }</PrimaryHeading>
      </HeadingWrap>
      { (!isSecondary && text) ? <Paragraph centered>{ text }</Paragraph> : '' }
      { button &&
      <SmartLink className={ buttonWrap } to={ button.route }>
        { isSecondary
          ? <Button borderedWhite transparent>{ button.text }</Button>
          : <GradientButton>{ button.text }</GradientButton>
        }
      </SmartLink>
      }
    </ContentWrap>
  </CallToActionWrap>
)

export default CallToAction
