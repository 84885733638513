import React from 'react'
import UseCaseCard from './UseCaseCard'
import ThreeUp from '../../Layouts/ThreeUp'

const UseCaseCardGrid = ({ items }) => {
  return (
    <ThreeUp>
      {items.map((item) => item && <UseCaseCard key={item.id} {...item} />)}
    </ThreeUp>
  )
}

export default UseCaseCardGrid
